import { AvField } from "availity-reactstrap-validation";
import React, {
  useEffect, useReducer, useState
} from "react";
import {
  Alert,
  Button,
  Col,
  Modal, ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { lte, startCase } from "lodash";

function EditSubIbModal(props) {
  const {
    show, toggle, member, customer, accountTypes, products, setIsAgreementValid,
    totals,
  } = props;
  const { t } = useTranslation();
  const getAccountName = (id) => {
    const account = accountTypes.find((item) => item._id === id);
    return `${(account?.title)}`;
  };

  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [agreement, setAgreement] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "INIT":
          return action.payload;
        case "UPDATE_TOTAL_REBATE":
          return {
            ...state,
            rebate: action.payload?.rebate,
          };
        case "UPDATE_TOTAL_COMMISSION":
          return {
            ...state,
            commission: action.payload?.commission,
          };
        case "UPDATE_ACCOUNT_REBATE":
          return {
            ...state,
            isValid: false,
            // rebate should be updated sum of all account's rebate
            rebate: state?.values?.reduce((acc, value) => {
              if (value?.accountTypeId === action.payload?.accountTypeId) {
                return acc + action.payload?.rebate;
              }
              return acc + value?.rebate;
            }, 0),
            values: state?.values?.map((value) => {
              if (value?.accountTypeId === action.payload?.accountTypeId) {
                const newProducts = Object.keys(value?.products ?? {})?.reduce((acc, prod) => {
                  acc[prod] = {
                    ...value?.products[prod],
                    rebate: action.payload?.rebate,
                  };
                  return acc;
                }, {});
                return {
                  ...value,
                  rebate: action.payload?.rebate,
                  products: newProducts,
                };
              }
              return value;
            }),
          };
        case "UPDATE_ACCOUNT_COMMISSION":
          return {
            ...state,
            isValid: false,
            // commission should be updated sum of all account's commission
            commission: state?.values?.reduce((acc, value) => {
              if (value?.accountTypeId === action.payload?.accountTypeId) {
                return acc + action.payload?.commission;
              }
              return acc + value?.commission;
            }, 0),
            values: state?.values?.map((value) => {
              if (value?.accountTypeId === action.payload?.accountTypeId) {
                const newProducts = Object.keys(value?.products ?? {})?.reduce((acc, prod) => {
                  acc[prod] = {
                    ...value?.products[prod],
                    commission: action.payload?.commission,
                  };
                  return acc;
                }, {});
                return {
                  ...value,
                  commission: action.payload?.commission,
                  products: newProducts,
                };
              }
              return value;
            }),
          };
        case "UPDATE_PRODUCT_REBATE":
          return {
            ...state,
            isValid: false,
            values: state?.values?.map((value) => {
              if (value?.accountTypeId === action.payload?.accountTypeId) {
                const newProducts = Object.keys(value?.products ?? {})?.reduce((acc, prod) => {
                  if (prod === action.payload?.productId) {
                    acc[prod] = {
                      ...value?.products[prod],
                      rebate: action.payload?.rebate,
                    };
                  } else {
                    acc[prod] = value?.products[prod];
                  }
                  return acc;
                }, {});
                return {
                  ...value,
                  products: newProducts,
                };
              }
              return value;
            }),
          };
        default:
          return state;
      }
    },
    {
      customerId: "",
      title: "",
      values: [],
    }
  );


  useEffect(() => {
    if (member) {
      setAgreement({
        type: "INIT",
        payload: member,
      });
    }
  }, []);

  useEffect(() => {
    if (agreement && agreement.values) {
      const {
        values 
      } = member;
      let maxRebate = 0;
      let maxCommission = 0;
      values.forEach((value) => {
        const {
          products = {},
        } = value;
        Object.keys(products).forEach((prod) => {
          const {
            rebate = 0, commission = 0
          } = products[prod];
          if (lte(rebate, maxRebate)) {
            maxRebate = rebate;
          }
          if (lte(commission, maxCommission)) {
            maxCommission = commission;
          }
        });
      });
    }
  }, [agreement]);

  useEffect(() => {
    if (!agreement.isValid) {
      const isTotalValid = (agreement?.rebate !== null && agreement?.commission !== null);
    
      const areFieldsValid = agreement?.values?.every((value) => {
        return Object.keys(value?.products ?? {})?.every((prod) => {
          return value?.products[prod]?.rebate !== null && value?.products[prod]?.commission !== null;
        });
      });
      // validate products where each account's products should not exceed the account's rebate
      const areProductsValid = agreement?.values?.every((value) => {
        return Object.keys(value?.products ?? {})?.every((prod) => {
          return (value?.products[prod]?.rebate <= value?.rebate && value?.products[prod]?.commission <= value?.commission);
        });
      }
      );

      let isValuesValid = true;
      // need to verify for every account type that their rebate and commission is less than the total rebate and commission
      for (let i = 0; i < agreement?.values?.length; i++) {
        const element = agreement?.values?.[i];
        const {
          accountTypeId, rebate, commission,
        } = element;
        const currentAccountTotal = totals?.find((total) => total?.accountTypeId === accountTypeId);
        if (currentAccountTotal) {
          if (currentAccountTotal?.rebate < rebate || currentAccountTotal?.commission < commission) {
            isValuesValid = false;
            break;
          }
        }
      }

      setIsValid(areFieldsValid && isTotalValid && areProductsValid && isValuesValid);
      if (!areFieldsValid) {
        setErrorMessage("Please fill all the fields!");
      } else if (!isTotalValid) {
        setErrorMessage("Total Rebate and Commission should be filled!");
      } else if (!areProductsValid) {
        setErrorMessage("Product's Rebate and Commission should be less than Account's Rebate and Commission!");
      } else if (!isValuesValid) {
        setErrorMessage("Account's Rebate and Commission should be less than Total Rebate and Commission!");
      }
    }
  }, [agreement]);

  useEffect(() => {
    if (isValid) {
      setIsAgreementValid({
        ...agreement,
        isValid,
      });
    } else {
      setIsAgreementValid({
        ...agreement,
        isValid,
      });
    }
  }, [isValid]);

  return (
    <Modal
      isOpen={show}
      toggle={toggle}
      centered
      size="md"
    >
      <ModalHeader
        toggle={toggle}
      >
        Edit Sub IB {customer?.firstName} {customer?.lastName} | Level {member?.level}
      </ModalHeader>
      <ModalBody>
        <div className="text-center">
          <h5>{t("Available Rebate and Commission")}</h5>
          {totals?.map((total) => (
            <Row key={total?.accountTypeId}>
              <Col>
                <label className="form-label">{
                  getAccountName(total?.accountTypeId)
                }</label>
              </Col>
              <Col  className="mb-3">
                <input
                  name={`${total?.accountTypeId}.rebate`}
                  type="number"
                  className="form-control"
                  placeholder="Rebate"
                  disabled
                  value={isValid ? parseFloat(total?.rebate ?? 0) : parseFloat(total?.commission) - parseFloat(agreement?.values?.find((value) => value?.accountTypeId === total?.accountTypeId)?.rebate ?? 0)}
                />
              </Col>
              <Col  className="mb-3">
                <input
                  name={`${total?.accountTypeId}.commission`}
                  type="number"
                  className="form-control"
                  placeholder="Commission"
                  disabled
                  value={isValid ? parseFloat(total?.commission ?? 0) : parseFloat(total?.commission) - parseFloat(agreement?.values?.find((value) => value?.accountTypeId === total?.accountTypeId)?.commission ?? 0)}
                />
              </Col>
            </Row>
          ))}
        </div>
        <hr className="my-3" />
        <Accordion className="mb-3" alwaysOpen
        >
          {agreement?.values && agreement?.values?.map((value) => (
            <Accordion.Item key={value?.accountTypeId} eventKey={value?.accountTypeId}>
              <Accordion.Header>
                <Row className="w-100 ">
                  <Col md="3">
                    {getAccountName(value?.accountTypeId)}
                  </Col>
                  <Col>
                    <input
                      name={`${value?.accountTypeId}.rebate`}
                      className="form-control"
                      // bsSize="sm"
                      type="number"
                      value={value?.rebate}
                      // errorMessage={t("Invalid value!")}
                      // validate={{
                      //   required: { value: true },
                      //   min: {
                      //     value: 0,
                      //     errorMessage: "Minimum is 0"
                      //   },
                        
                      // }}
                      min={0}
                      onChange={(e) => {
                        // if -ve don't update
                        if (parseFloat(e.target.value) < 0) return;
                        setAgreement({
                          type: "UPDATE_ACCOUNT_REBATE",
                          payload: {
                            accountTypeId: value?.accountTypeId,
                            rebate: parseFloat(e.target.value),
                          },
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <input
                      name={`${value?.accountTypeId}.commission`}
                      // bsSize="sm"
                      type="number"
                      value={value?.commission}
                      // errorMessage={t("Invalid value!")}
                      // validate={{
                      //   required: { value: true },
                      //   min: {
                      //     value: 0,
                      //     errorMessage: "Minimum is 0"
                      //   },
                      // }}
                      min={0}
                      className="form-control"
                      onChange={(e) => {
                        if (parseFloat(e.target.value) < 0) return;
                        setAgreement({
                          type: "UPDATE_ACCOUNT_COMMISSION",
                          payload: {
                            accountTypeId: value?.accountTypeId,
                            commission: parseFloat(e.target.value),
                          },
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Accordion.Header>
              <Accordion.Body>
                {Object.keys(value?.products ?? {})?.map((prod, prodIdx) => (
                  <Row
                    key={prodIdx}
                    className="my-1 align-items-center"
                  > 
                    <Col md="3">{
                      startCase(products.find((p) => p?.toLowerCase() === prod?.toLowerCase()))
                    }</Col>
                    <Col>
                      <input
                        name={`${value?.accountTypeId}.products.${prod}.rebate`}
                        // bsSize="sm"
                        type="number"
                        // validate={{
                        //   required: { value: true },
                        //   min: {
                        //     value: 0,
                        //     errorMessage: "Minimum is 0"
                        //   }
                        // }}
                        min={0}
                        className="form-control"
                        value={value?.products[prod]?.rebate}
                        // errorMessage={t("Invalid Product Rebate value!")}
                        onChange={(e) => {
                          setAgreement({
                            type: "UPDATE_PRODUCT_REBATE",
                            payload: {
                              accountTypeId: value?.accountTypeId,
                              productId: prod,
                              rebate: parseFloat(e.target.value),
                            },
                          });
                        }}
                      />
                    </Col>
                    <Col>
                      <input
                        name={`${value?.accountTypeId}.products.${prod}.commission`}
                        // bsSize="sm"
                        type="number"
                        // errorMessage={t("Invalid Product Commission value!")}
                        // validate={{
                        //   required: { value: true },
                        //   min: {
                        //     value: 0,
                        //     errorMessage: "Minimum is 0"
                        //   }
                        // }}
                        min={0}
                        className="form-control"
                        value={value?.products[prod]?.commission}
                        onChange={(e) => {
                          setAgreement({
                            type: "UPDATE_PRODUCT_TOTAL_COMMISSION",
                            payload: {
                              accountTypeId: value?.accountTypeId,
                              productId: prod,
                              commission: parseFloat(e.target.value),
                            },
                          });
                        }}
                      />
                    </Col>
                  </Row>
                ))}
                <Row>
                  <Col md="3">Markup</Col>
                  <Col>
                    <AvFieldSelect
                      name={`members[${1}].values[${1}].markup`}
                      options={([]).map((obj) => {
                        return ({
                          label: `${obj}`,
                          value: obj
                        });
                      })}
                    />
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        {!isValid && errorMessage && (
          <Alert color="danger">
            {errorMessage}
          </Alert>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={toggle}
        >
          {t("Close")}
        </Button>
        <Button
          color="primary"
          onClick={()=> {
            setIsAgreementValid({
              ...agreement,
              isValid,
            });
            toggle();
          }}
          disabled={!isValid}
        >
          {t("Save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default React.memo(EditSubIbModal);