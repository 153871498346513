import React, { useEffect, useState } from "react";
import salesDedicatedLinks from "common/salesDedicatedLinks";
import { useDispatch, useSelector } from "react-redux";
import { showSuccessNotification } from "store/notifications/actions";
import {
  Card,
  Col,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import TableLoader from "components/Common/Loader";
const { cpUrl } = require("content");

const SalesDedicatedLinks = () => {
  const dispatch = useDispatch();
  const { userData, loading } = useSelector((state) => state.Profile);
  const { accountTypes } = useSelector((state) => state.tradingAccountReducer);
  const [userAccountTypes, setAccountTypeUsers] = useState([]);
  const [userDedicatedLinks, setUserDedicatedLinks] = useState([]);
  
  useEffect(() => {
    if (accountTypes && accountTypes.length > 0) {
      const filteredAccounts = accountTypes.filter((accountType) => 
        accountType.users?.length > 0 && accountType.users.includes(userData._id)
      );
      setAccountTypeUsers(filteredAccounts);
    }
  }, [accountTypes, userData._id]);
  
  useEffect(() => {
    if (userAccountTypes.length > 0) {
      setUserDedicatedLinks(() => {
        const newLinks = userAccountTypes.map(
          (type) => `${cpUrl}/register/client/live?salesRef=${userData.userId}&accTypeRef=${type.recordId}&accTypeTitle=${type.title}`
        );
        return newLinks;
      });
    }
  }, [userAccountTypes]);
  
  
  return (
    <Card className="card-animate mb-2">
      <Row className="justify-content-center">
        <Col className="text-center" lg={12}>
          <div className="card-header">
            <div className="card-title">
              <h4 className="color-primary"> Dedicated Links</h4>
            </div>
            {loading && (
              <TableLoader />
            )}
            {!loading && userData.userId && salesDedicatedLinks.map((link) => {
              return (
                <>
                  <div>
                    <Link to="#"
                      className="mdi mdi-clipboard-check-multiple-outline font-size-20 me-2"
                      onClick={() => {
                        navigator.clipboard.writeText(`${link}?salesRef=${userData.userId}`);
                        dispatch(showSuccessNotification("Link copied to clipboard"));
                      }}
                    ></Link>
                    <span>{`${link}?salesRef=${userData.userId}`}</span>
                  </div>
                </>
              );
            })}
            {!loading && userData.userId && userDedicatedLinks.map((link) => {
              const url = new URL(link);
              const params = new URLSearchParams(url.search);
            
              const accTypeTitle = params.get("accTypeTitle");
              params.delete("accTypeTitle");
            
              const cleanedLink = `${url.origin}${url.pathname}?${params.toString()}`;
            
              return (
                <div key={link}>
                  <Link to="#"
                    className="mdi mdi-clipboard-check-multiple-outline font-size-20 me-2"
                    onClick={() => {
                      navigator.clipboard.writeText(cleanedLink);
                      dispatch(showSuccessNotification("Link copied to clipboard"));
                    }}
                  ></Link>
                  <span>{cleanedLink}</span>
                  {accTypeTitle && <span className="ms-2">({accTypeTitle})</span>}
                </div>
              );
            })}

            {!loading && !userData.userId && (
              <div className="mt-4">
                <h5 className="text-danger">
                  Failed to generate dedicated links. Please contact support.
                </h5>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default SalesDedicatedLinks;